/**
 * Checks if the current site is a PriceSpider site
 * @param {brand} brand current brand string
 * @param {locale} locale current locale string
 * @returns {boolean} true if the site is a PriceSpider site
 */
export function isPriceSpiderSite(brand, locale) {
  const supportedBrands = ['go-solutions', 'now-fresh', 'summit'];
  const supportedLocales = ['en-us', 'en-ca', 'fr-ca'];
  return supportedBrands.includes(brand) && supportedLocales.includes(locale);
}
