import enUS from '../en-us';
const en = {
  common: {
    ...enUS.common,
    navigation: {
      ...enUS.common.navigation,
      whereToBuy: 'Buy Worldwide',
    },
  },
  product: {
    ...enUS.product,
    hero: {
      ...enUS.product.hero,
      whereToBuy: 'Where To Buy',
    },
  },
};

export default Object.assign({}, enUS, en);
