import Script from 'next/script';
import { isPriceSpiderSite } from './pricespider';

/**
 * HotJar tracking script
 * @param {string} [props] component props
 * @param {string} [props.trackingId] HotJar tracking id
 * @returns {JSX.Element} react component
 */
export function HotjarScript({ trackingId }) {
  /* eslint-disable */
  return (
    <>
      <Script id="analyticsHJ" strategy="lazyOnload">
        {`
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${trackingId},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </Script>
    </>
  );
  /* eslint-enable */
}

/**
 * Facebook tracking script
 * @param {string} [props] component props
 * @param {string} [props.pixelId] Facebook pixel id
 * @returns {JSX.Element} react component
 */
export function FacebookScript({ pixelId }) {
  /* eslint-disable */
  return (
    <>
      <Script id="analyticsFB" strategy="lazyOnload">
        {`
          !function(f,b,e,v,n,t,s){
            if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
          }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${pixelId});
          fbq('track', 'PageView');
        `}
      </Script>
      <noscript id="noScriptFB">
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`}
        />
      </noscript>
    </>
  );
  /* eslint-enable */
}

/**
 * Google Analytics tracking script
 * @param {string} [props] component props
 * @param {string} [props.trackingId] Google Analytics tracking id
 * @returns {JSX.Element} react component
 */
export function GoogleAnalyticsScript({ trackingId }) {
  /* eslint-disable */
  return (
    <>
      <Script
        id="analyticsGTAG"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}
      />
      <Script id="analyticsGTM" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){
            w[l]=w[l]||[];
            w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${trackingId}');
        `}
      </Script>
    </>
  );
  /* eslint-enable */
}

/**
 * Google Analytics tracking script.
 * This one is added right after the opening <body> tag
 * @param {string} [props] component props
 * @param {string} [props.trackingId] Google Analytics tracking id
 * @returns {JSX.Element} react component
 */
export function GoogleAnalyticsNoscript({ trackingId }) {
  /* eslint-disable */
  return (
    <noscript id="noScriptGTM">
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${trackingId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}></iframe>
    </noscript>
  );
  /* eslint-enable */
}

/**
 * Twitter tracking script.
 * @param {string} [props] component props
 * @param {string} [props.trackingId] Twitter tracking id
 * @returns {JSX.Element} react component
 */
export function TwitterScript({ trackingId }) {
  /* eslint-disable */
  return (
    <>
      <Script id="analyticsTwitter" strategy="lazyOnload">
        {`
          !function(e,t,n,s,u,a){
            e.twq||(s=e.twq=function(){
              s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },
            s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a)
            )}(window,document,'script');
          twq('init','${trackingId}');
          twq('track','PageView');
        `}
      </Script>
    </>
  );
  /* eslint-enable */
}

/**
 * Pricespider Tracking Script
 * @param {string} [props] component props
 * @param {string} [props.accountNumber] Pricespider petcurean account number
 * @returns {JSX.Element} react component
 */
export function PricespiderTrackingScript({ accountNumber, brand, locale }) {
  return (
    isPriceSpiderSite(brand, locale) && (
      <Script
        id="analyticsPricespider"
        strategy="lazyOnload"
        src={`//cdn.pricespider.com/1/${accountNumber}/ps-utid.js`}></Script>
    )
  );
}

/**
 * Pricespider Widget Script
 * @param {string} [props] component props
 * @param {string} [props.accountNumber] Pricespider account
 * @param {string} [props.brand] Brand for the widget
 * @param {string} [props.locale] Locale for the widget
 * @returns {JSX.Element} react component
 */
export function PricespiderWidgetScript({ accountNumber, brand, locale }) {
  const [language, country] = locale.toLowerCase().split('-');

  return (
    isPriceSpiderSite(brand, locale) && (
      <Script
        id="pricespiderWidget"
        src="//cdn.pricespider.com/1/lib/ps-widget.js"
        data-ps-account={accountNumber}
        data-ps-country={(country || 'US').toUpperCase()}
        data-ps-language={language || 'en'}
        strategy="beforeInteractive"
      />
    )
  );
}
